.layout-sidebar {
    position: fixed;
    width: 236px;
    height: calc(100vh - 7rem);
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    margin-top: calc(5.925rem - .25rem);
    margin-left: .825rem;
    transition: transform $transitionDuration, left $transitionDuration;
    background-color: var(--surface-card);
    //background: linear-gradient(10deg, rgb(48, 86, 140), rgb(23, 35, 88));
    border-radius: $borderRadius;
    padding: 0.75rem .9rem;
    white-space: nowrap !important;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);

}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    user-select: none;

    .layout-root-menuitem {
        >.layout-menuitem-root-text {
            font-size: .857rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--surface-900);
            margin: .75rem 0;
        }

        >a {
            display: none;
        }
    }

    li.active-menuitem {
        >a {
            .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }

        >ul {
            max-height: auto;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            outline: 0 none;
            color: var(--text-color);
            cursor: pointer;
            padding: .75rem 1rem;
            border-radius: $borderRadius;
            transition: background-color $transitionDuration, box-shadow $transitionDuration;

            .layout-menuitem-icon {
                margin-right: .5rem;
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;
                transition: transform $transitionDuration;
            }

            &.rotated-icon {
                .layout-menuitem-icon {
                    transform: rotate(90deg);
                }
            }

            &.active-route {
                font-weight: 700;
                color: var(--primary-color);
            }

            &:hover {
                background-color: var(--surface-hover);
            }

            &:focus {
                @include focused-inset();
            }
        }

        ul {
            overflow: hidden;
            max-height: 0;
            border-radius: $borderRadius;

            li {
                a {
                    margin-left: 1rem;
                }

                li {
                    a {
                        margin-left: 2rem;
                    }

                    li {
                        a {
                            margin-left: 2.5rem;
                        }

                        li {
                            a {
                                margin-left: 3rem;
                            }

                            li {
                                a {
                                    margin-left: 3.5rem;
                                }

                                li {
                                    a {
                                        margin-left: 4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.layout-submenu-enter-active {
    max-height: 1000px !important;
    transition: max-height 1s ease-in-out;
}

.layout-submenu-enter-done {
    max-height: 1000px !important;
}

.layout-submenu-exit {
    max-height: 1000px !important;
}

.layout-submenu-exit-active {
    overflow: hidden;
    max-height: 0 !important;
    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}

.layout-sidebar .layout-menu li.menu-separator {
    >div>a {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
}

.legend-container {
    overflow: hidden;
}

.layout-menuitem-active {
    color: white
}

.layout-menuitem-normal {
    color: var(--primary-color);
}