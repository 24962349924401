.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    top: 0rem;
    padding: 6rem 1rem 2rem 4rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    //flex: 1 1 auto;
}

.layout-main-auto-flex {
    flex: 1 1 auto !important
}

.tab-menu {
    background-color: rgba(31, 45, 63, 0.75) !important;
    width: 100% !important;
    padding: .5rem .5rem 0rem .5rem;
}

.layout-static-inactive .layout-main-container {
    padding-left: 1rem !important;
}

.responsive-modals {
    width: 50vw !important;
}