* {
    box-sizing: border-box;
}

html {
    height: 100%;
    height: 100vh;
    font-size: 14px;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .background-helix {
        background-size: cover;
        background-image: url(../../helix-dark.jpg);
        background-color: var(--surface-ground);
    }

    .background-helix-graphs {
        background-size: contain;
        background-image: url(../../helix-dark-flipped.png);
    }
}


body>#root>div {
    min-height: 100vh;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-wrapper {
    min-height: 100vh;
}

.new-class {
    background-color: blueviolet !important;
    color: rgb(255, 127, 127) !important;
    background: rgb(126, 42, 165) !important;
}


.center-modal-header {
    .p-dialog-title {
        vertical-align: middle !important;
        text-align: center !important;
        font-size: 2.5rem !important
    }
}