.layout-topbar-open {
    width: 242px !important;
}

.layout-topbar-closed {
    width: 40px !important;
}

.simulation-player {
    height: 380px;
    width: 380px;
}

.layout-topbar {
    position: fixed;
    height: 4.75rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 1rem;
    background-color: var(--surface-card);
    //background: linear-gradient(90deg, rgb(9, 20, 67), rgb(39, 71, 117) 55%, rgb(23, 35, 82));
    transition: left $transitionDuration;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);

    .layout-topbar-logo {
        display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        width: 212px;
        border-radius: 12px;

        img {
            height: 2.5rem;
            margin-right: .5rem;
        }

        &:focus {
            @include focused();
        }
    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        transition: background-color $transitionDuration;

        &:hover {
            color: var(--text-color);
            background-color: var(--surface-hover);
        }

        &:focus {
            @include focused();
        }

        i {
            font-size: 1.5rem;
        }

        span {
            font-size: 1rem;
            display: none;
        }
    }

    .layout-menu-button {
        margin-left: 2rem;
    }

    .layout-topbar-menu-button {
        display: none;

        i {
            font-size: 1.25rem;
        }
    }

    .layout-topbar-menu {
        margin: 0 0 0 auto;
        padding: 0;
        list-style: none;
        display: flex;

        .layout-topbar-button {
            margin-left: 1rem;
        }
    }
}

.layout-static-inactive .layout-topbar-button {
    margin-left: -.25rem !important; //This is for when the lateral menu is collapsed, the hamburger icon moves to the left
}

.layout-topbar h4 {
    margin: 1.25rem 0rem 1.25rem 0rem !important;
}


@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;

        .layout-topbar-logo {
            margin-left: 0 !important;
            width: auto;
            order: 2;
            background-color: lawngreen;
        }

        .layout-menu-button {
            order: 1;
            margin-left: 0rem !important;
        }

        .top-fix {
            margin-right: 3rem !important; //Important fix for issue with title too wide on mobile
        }

        .top-fix-menu-open {
            margin-right: 5rem !important; //Important fix for issue with title too wide on mobile
        }

        .layout-topbar-menu-button {
            display: inline-flex;
            margin-left: 0;
            order: 3;
        }

        .layout-topbar-menu {
            margin-left: 0;
            position: absolute;
            flex-direction: column;
            background-color: var(--surface-overlay);
            box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);
            border-radius: 12px;
            padding: 1rem;
            right: 2rem;
            top: 5rem;
            min-width: 15rem;
            display: none;
            -webkit-animation: scalein 0.15s linear;
            animation: scalein 0.15s linear;

            &.layout-topbar-menu-mobile-active {
                display: block
            }

            .layout-topbar-button {
                margin-left: 0;
                display: flex;
                width: 100%;
                height: auto;
                justify-content: flex-start;
                border-radius: 12px;
                padding: 1rem;


                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    font-weight: medium;
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .layout-topbar h4 {
        font-size: 16px !important;
        margin: 1.25rem 0rem 1.25rem 0rem !important;
    }
}

@media (max-width: 300px) {
    .layout-topbar h4 {
        font-size: 12px !important;
        margin: 1.25rem 0rem 1.25rem 0rem !important;
    }
}