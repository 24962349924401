@import './_variables';
@import "./_mixins";
@import "./_main";
@import "./_topbar";
@import "./_menu";
@import "./_config";
@import "./_content";
@import "./_footer";
@import "./_responsive";
@import "./_utils";
@import "./_typography";

.tooltip-simtable {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip-simtable .tooltip-simtable-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-simtable:hover .tooltip-simtable-text {
  visibility: visible;
  min-width: 100px;
  width: 428px;
}

.apexcharts-menu-item.exportSVG {
  display: none;
}

.apexcharts-menu-item.exportCSV {
  display: none;
}

.topbar-width-shift {
  width: 236px;
  display: flex;
  justify-content: flex-end;
}

.topbar-width-shift-open {
  width: 236px;
  display: flex;
  justify-content: flex-end;
}