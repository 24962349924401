@media screen and (min-width: 1960px) {

    .layout-main,
    .landing-wrapper {
        //width: 1504px;
        //margin-left: auto !important;
        //margin-right: auto !important;
    }
}

@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                padding-left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.layout-overlay-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 240px;
                padding-left: 2rem
            }

            &.layout-static-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                    margin-left: 0;
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 2rem
                }
            }
        }

        .layout-mask {
            display: none;
        }
    }

}

@media (max-width: 991px) {
    .responsive-modals {
        width: 80% !important;
    }

    .legend-container {
        overflow: hidden;
        overflow-x: scroll;
    }

    .sticky2a {
        top: 9.5rem !important;
    }

    .sticky2b {
        top: 9.5rem !important;
    }

    .sticky-transparent {
        top: 9.5rem !important;
    }

    .blocked-scroll {
        overflow: hidden;
    }

    .layout-wrapper {
        .layout-main-container {
            margin-left: 0;
            padding: 6rem 1rem 2rem 1rem;
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            width: 236px;
            padding: 0.75rem .9rem;
            height: calc(100vh - 7rem);
            margin-left: 0rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .layout-mask {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 998;
            width: 100%;
            height: 100%;
            background-color: var(--maskbg);
        }

        &.layout-mobile-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
                animation: fadein $transitionDuration;
            }
        }
    }

    .p-image .p-component .mt-1 .p-image-preview-container>img {
        width: 250px !important;
    }


    ul.p-tabmenu-nav li {
        width: 100% !important;
        border-color: #efefef !important;

    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        padding: .33rem;
        font-size: .9rem;
        //align-items: flex-end;
    }

    .topbar-width-shift {
        width: 0px !important;
        justify-content: flex-start !important;
    }

    .topbar-width-shift-open {
        width: 236px;
        display: flex;
        justify-content: flex-end;
    }
}

@media (min-width: 601px) {
    /* .sticky2a{
        top: 8.5rem !important;
    }
    .sticky2b{
        top: 8.5rem !important;
    }
    .sticky-transparent{
        top: 8.5rem !important;
    } */
}

/* 
@media (max-width: 401px) {
    .sticky2a {
        top: 8.5rem !important;
    }

    .sticky2b {
        top: 8.5rem !important;
    }

    .sticky-transparent {
        top: 8.5rem !important;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        padding: .5rem !important;
        font-size: 12px;
    }
} */