/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card-background {
    background: linear-gradient(60deg, rgba(8, 36, 75, 0.9), transparent);
}

.raised-border {
    box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(140, 140, 140, 0.5), inset -0.3em -0.3em 0.2em 0 rgba(0, 0, 0, 0.5);
    padding: .5rem;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;
    background: linear-gradient(60deg, rgba(8, 36, 75, 0.9), transparent);

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {

    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.card-medium {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: .1rem .2rem 0rem .15rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;
    background: linear-gradient(60deg, rgba(8, 36, 75, 0.9), transparent);

    &:last-child {
        margin-bottom: 0;
    }
}

.card-small {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 0rem 0rem 0rem 0rem;
    margin-bottom: 0rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;
    background: linear-gradient(60deg, rgba(8, 36, 75, 0.9), transparent);

    &:last-child {
        margin-bottom: 0;
    }
}

.pd-small {
    padding: .5rem .375rem .1rem .375rem !important;
}

.pd-medium {
    padding: .5rem .6rem .4rem .15rem !important;
}