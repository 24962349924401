/* Place your extensions here to make migration to a new release easier. */

@import "./styles/demo/Demos.scss";

@import "./styles/layout/layout.scss";

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    //display: flex !important;
    justify-content: flex-end !important;
    //width: 50%;
    background-color: #17212f;
    z-index: 444;
    top: 4.75rem;
    padding: .75rem 0rem 0rem .5rem;

}

.hide-filter-text {}

.sticky-transparent {
    background-color: transparent !important;
    pointer-events: none !important;
}

@media (max-width: 400px) {
    .sticky-transparent {
        display: flex !important;
    }

    .p-image-preview-container>img {
        width: 300px !important;
    }

    .simulation-player {
        width: 320px !important;
        height: 320px !important;
    }
}


@media (max-width: 1100px) {
    .sticky-transparent {
        display: none !important;
    }

    .hide-filter-text {
        display: none !important;
    }
}




.sticky-full {
    position: fixed;
    display: flex !important;
    justify-content: flex-end !important;
    width: 100%;
    background-color: transparent !important;
    z-index: 444;
    top: 4.75rem;
}

.sticky2a {
    width: 100% !important;
    top: 4.8rem !important;
    padding: 0rem 0rem 0rem 0rem;
    //background-color: blue !important;
}

.sticky2b {
    display: flex;
    justify-content: flex-end !important;
    width: 100% !important;
    top: 4.8rem !important;
    padding: 0rem 0rem 0rem 0rem;
    //background-color: chartreuse !important;
    pointer-events: none !important;

    .p-selectbutton {
        pointer-events: auto !important; //Fix due to the topbar being unclickable when sticky2b is overlapping it
    }
}

.sticky3 {
    width: 100% !important;
    top: 8.75rem !important;
    background-color: transparent !important;
    padding: .25rem .3rem .25rem 0rem !important;
    margin: 0rem 0rem 0rem 0rem;
}

.unsticky-text {
    width: 100%;
    text-align: end;
    margin: .3rem 0rem 0rem 0rem;
    //transform: translateY(-8px);
}

.horizontal-dotted-line {
    border-top: 3px dotted;
    margin-top: 4px;
    //width: 32px;
    //height: 4px;
    //margin-right: 10px !important;
}

.text-white {
    color: white;
}

.lateral-clan-container {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding: 1rem 0rem 1rem 0rem !important;
    margin: 0rem 0rem 0rem 0rem !important;
    //width: 100% !important;

}

.lateral-clan-main {
    overflow-y: hidden;
    overflow-x: scroll;
}

.lateral-graph-title {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    //font-weight: 600 !important;
}

//Apex charts line event annotation fixes
svg:not(:root) {
    overflow-clip-margin: .25rem;
    overflow: hidden;
    text-overflow: unset;
    //background: red !important;
}

svg.apexcharts-zoomable.apexcharts-svg {
    //background: red !important;
    padding-left: 0rem;
    //position: absolute;
    width: 97.5%;
}

div.apexcharts-canvas.apexcharts-theme-light {
    display: flex;
    justify-content: center;
}

.time-aligned-toggle {
    min-width: 14rem;
    min-height: 2.55rem;

    &:hover {
        background-color: rgba(8, 36, 75, 0.9) !important;
        color: darkgray !important;
        //background: white !important;
    }
}


g.apexcharts-xaxis text tspan {}


.p-treetable .p-treetable-tbody>tr {
    background-color: rgba(8, 36, 75, 0);
}

.p-treetable .p-treetable-thead {
    background-color: rgba(8, 36, 75, 0.1) !important;
    background: transparent !important;
}

.p-treetable .p-treetable-thead>tr>th {
    background-color: rgba(8, 36, 75, 0.1) !important;
    background: transparent !important;
}

.p-treetable .p-treetable-scrollable-header {
    background-color: rgba(31, 45, 63, 0.5) !important;
}


.p-accordion-content {
    background-color: transparent !important;
    background: transparent !important;
}

.p-accordion-header-link {
    /* Content */
    background-color: rgba(31, 45, 63, 0.4) !important;

    &:hover {
        background-color: rgba(31, 45, 63, 0.2) !important;
        color: darkgray !important;
        //background: white !important;
    }
}