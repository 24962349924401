h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: var(--surface-900);

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

mark {
    background: #FFF8E1;
    padding: .25rem .4rem;
    border-radius: $borderRadius;
    font-family: monospace;
}

blockquote {
    margin: 1rem 0;
    padding: 0 2rem;
    border-left: 4px solid #90A4AE;
}

hr {
    border-top: solid var(--surface-border);
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

p {
    margin: 0 0 1rem 0;
    line-height: 1.5;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-treetable .p-treetable-tbody {
    .wrap-fix {
        // text-wrap: nowrap !important;
        //white-space: nowrap;
    }

    .center-param-fix {}
}

@media screen and (max-width: 1200px) {

    /*adjust*/
    .wrap-fix {
        text-wrap: nowrap !important;
        white-space: nowrap;
        //font-size: .9rem;
        overflow: elipses;
    }

    .center-param-fix {
        text-align: center !important;
    }
}

@media screen and (max-width: 800px) {

    /*adjust*/
    .wrap-fix {
        text-wrap: nowrap !important;
        white-space: nowrap;
        font-size: .9rem;
        overflow: elipses;
    }

    .center-param-fix {
        text-align: right !important;
    }
}