body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.select-buttons .p-buttonset .p-component {
  width: 7rem !important;
  height: 2.75rem;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  width: 100%;
  background-color: #17212f;
  z-index: 444;
  top: 5rem;
  padding: .5rem 0rem 0rem 0rem;
  margin: 0rem 0rem 0rem 0rem;

}

/* The sticky class is added to the header with JS when it reaches its scroll position */

.apexcharts-gridline {
  stroke-width: .4px;
}

/* .apexcharts-gridline .apexcharts-gridline-medium {
  stroke-width: .5px;
} */
.apexcharts-tooltip {
  color: black;
}

.apexcharts-yaxis-label {
  padding-left: 0rem !important;
  margin-left: 0rem !important;
}

.apexcharts-svg {
  overflow: visible;
}

div .apexcharts-menu-item {
  color: black;
}

.chart-title {
  margin-top: 32px !important;
  color: red;
}

.border-drought {
  border-color: darkgoldenrod !important;
}

.border-stds {
  border-color: darkred !important;
}

.border-supernovas {
  border-color: white !important;
}

.font-legend {
  font-size: 24px;
}

.select-buttons-clan-compare {
  display: flex !important;
  justify-content: flex-end !important;

  padding: .5rem .5rem .75rem .5rem !important;
  /* width: 100% !important; /*This will add the filter buttons to below the 'clan compare' text/* */
}

.select-buttons-clan-compare .p-buttonset .p-component {
  min-width: 9rem !important;
  min-height: 2.25rem;
}


/* .apexcharts-xcrosshairs {
  stroke-linecap: round;
  stroke-width: 4px;
  stroke-dasharray: 2px;
  stroke: aqua;
} */

/* .apexcharts-legend-marker {
  border-top: 3px dotted white !important;
  stroke-width: 4px !important;
  stroke-dasharray: 2px !important;
  color: transparent !important;
  background: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  height: 3px !important;
  width: 44px !important;
} */